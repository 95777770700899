import { IconButton } from "@clipboard-health/ui-components";

interface WorkplaceReviewAnonymizeIconProps {
  onAnonymize: () => void;
}

export function WorkplaceReviewAnonymizeIcon(props: WorkplaceReviewAnonymizeIconProps) {
  const { onAnonymize } = props;

  return (
    <IconButton
      invert
      size="small"
      iconType="eye"
      variant="contained"
      LinkComponent="symbol"
      onClick={(event) => {
        event.stopPropagation();
        onAnonymize();
      }}
    />
  );
}
