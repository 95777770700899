import { Card } from "@clipboard-health/ui-components";
import { Text, useModalState } from "@clipboard-health/ui-react";
import { CardActionArea, CardContent, Stack } from "@mui/material";
import {
  type WorkplaceCommentAttributesType,
  type WorkplaceCommentType,
} from "@src/appV2/Reviews/api/useWorkplaceComments";
import { Reaction } from "@src/appV2/Reviews/types";
import { parseISO } from "date-fns";

import { WorkplaceReviewCommentAuthorInfo } from "./CommentAuthorInfo";
import { ConfirmAnonymizeCommentBottomSheet } from "./ConfirmAnonymizeCommentBottomSheet";
import { WorkplaceReviewCommentLikesButton } from "./LikesButton";
import { WorkplaceReviewCommentRepliesAndLikesWrapper } from "./RepliesAndLikesWrapper";
import { WorkplaceReviewCommentRepliesCountIcon } from "./RepliesCountIcon";
import { WorkplaceReviewAnonymizeIcon } from "./WorkplaceReviewAnonymizeIcon";

interface WorkplaceReviewCommentCardProps {
  comment: WorkplaceCommentType;
  workplaceId: string;
  viewerIsAuthor: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onLikeChanged: (reaction: WorkplaceCommentAttributesType["workerReaction"]) => void;
  onAnonymized: ({ commentId, workplaceId }: { commentId: string; workplaceId: string }) => void;
}

export function WorkplaceReviewCommentCard(props: WorkplaceReviewCommentCardProps) {
  const { comment, workplaceId, onClick, onLikeChanged, viewerIsAuthor, onAnonymized } = props;
  const { createdAt, workerInfo, text, aggregates, workerReaction } = comment.attributes;
  const { id: workerId, name: workerName } = workerInfo;
  const { totalLikes, totalReplies } = aggregates;

  const modalState = useModalState();

  return (
    <>
      <Card outlined variant="tertiary">
        <CardActionArea onClick={onClick}>
          <CardContent
            sx={{
              padding: 7,
              height: "100%",
              minHeight: "11rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Text variant="body2" sx={{ paddingBottom: 6 }}>
              {text}
            </Text>

            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <WorkplaceReviewCommentAuthorInfo
                workerName={workerName}
                workerId={workerId}
                createdAt={parseISO(createdAt)}
              />

              <WorkplaceReviewCommentRepliesAndLikesWrapper>
                {viewerIsAuthor && (
                  <WorkplaceReviewAnonymizeIcon
                    onAnonymize={() => {
                      modalState.openModal();
                    }}
                  />
                )}

                <WorkplaceReviewCommentRepliesCountIcon count={totalReplies} />

                <WorkplaceReviewCommentLikesButton
                  workplaceId={workplaceId}
                  commentId={comment.id}
                  isLiked={workerReaction === Reaction.LIKE}
                  count={totalLikes}
                  onLikeChanged={(reaction) => {
                    onLikeChanged(reaction);
                  }}
                />
              </WorkplaceReviewCommentRepliesAndLikesWrapper>
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
      {viewerIsAuthor && (
        <ConfirmAnonymizeCommentBottomSheet
          modalState={modalState}
          commentId={comment.id}
          workplaceId={workplaceId}
          onAnonymized={onAnonymized}
        />
      )}
    </>
  );
}
