import { Input } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { SHIFT_DISCOVERY_WORKPLACE_REVIEWS_COMMENT_MODAL_PATH } from "@src/appV2/redesign/ShiftDiscovery/paths";
import { useWorkplaceModalsDataContext } from "@src/appV2/redesign/ShiftDiscovery/useWorkplaceModalsDataContext";
import {
  useWorkplaceComments,
  type WorkplaceCommentType,
} from "@src/appV2/Reviews/api/useWorkplaceComments";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { parseISO } from "date-fns";
import { debounce } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { WorkplaceReviewCommentCard } from "./Card";
import { useUpdateCachedComment } from "./useUpdateCachedComment";

interface WorkplaceCommentListProps {
  workplaceId: string;
}

export function WorkplaceCommentList(props: WorkplaceCommentListProps) {
  const { workplaceId } = props;

  const history = useHistory();
  const worker = useDefinedWorker();

  const { generateModalPath } = useWorkplaceModalsDataContext();
  const commentsParams = { workplaceId, page: { size: 200 } };
  const { data: comments } = useWorkplaceComments(commentsParams);

  const inputRef = useRef<HTMLInputElement>(null);

  const [filteredComments, setFilteredComments] = useState<WorkplaceCommentType[]>(comments ?? []);

  const debouncedFilter = useMemo(
    () =>
      debounce((searchTerm: string) => {
        if (!comments) {
          return;
        }

        const sortedComments = comments.sort((a, b) => {
          return (
            parseISO(b.attributes.createdAt).getTime() - parseISO(a.attributes.createdAt).getTime()
          );
        });

        if (!searchTerm) {
          setFilteredComments(sortedComments);
          return;
        }

        const lowercaseSearch = searchTerm.toLowerCase();

        const filtered = lowercaseSearch
          ? sortedComments.filter((comment) =>
              comment.attributes.text.toLowerCase().includes(lowercaseSearch)
            )
          : sortedComments;

        setFilteredComments(filtered);
      }, 300),
    [comments]
  );

  useEffect(() => {
    debouncedFilter(inputRef.current?.value ?? "");
  }, [debouncedFilter]);

  const { updateComment } = useUpdateCachedComment({
    workplaceId: String(workplaceId),
    commentsParams,
  });

  return (
    <Stack spacing={7}>
      <Text semibold variant="h6" sx={{ paddingLeft: 5 }}>
        Reviews
      </Text>

      <Input
        ref={inputRef}
        iconType="search"
        placeholder="Search reviews..."
        size="small"
        onChange={(event) => {
          debouncedFilter(event.target.value);
        }}
      />

      <Stack spacing={3}>
        {comments?.length === 0 && (
          <Text variant="h6" sx={{ paddingTop: 7, textAlign: "center" }}>
            No reviews yet.
          </Text>
        )}

        {comments?.length !== 0 && filteredComments.length === 0 && (
          <Text variant="h6" sx={{ paddingTop: 7, textAlign: "center" }}>
            No reviews matching your search
          </Text>
        )}

        {filteredComments.map((comment) => (
          <WorkplaceReviewCommentCard
            key={comment.id}
            comment={comment}
            workplaceId={String(workplaceId)}
            viewerIsAuthor={comment.attributes.workerInfo.id === worker.userId}
            onClick={() => {
              history.push(
                generateModalPath(SHIFT_DISCOVERY_WORKPLACE_REVIEWS_COMMENT_MODAL_PATH, {
                  workplaceId,
                  commentId: comment.id,
                })
              );
            }}
            onLikeChanged={(reaction) => {
              updateComment(comment.id, { reaction: reaction ?? undefined });
            }}
            onAnonymized={() => {
              updateComment(comment.id, { anonymized: true });
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
}
