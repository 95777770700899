import { isDefined } from "@clipboard-health/util-ts";
import { type GetCommentResponse } from "@src/appV2/Reviews/api/useGetComment";
import { getCommentUrl } from "@src/appV2/Reviews/api/usePatchComment";
import {
  getWorkplaceCommentsQueryKey,
  type WorkplaceCommentAttributesType,
  type WorkplaceCommentsParams,
  type WorkplaceCommentType,
} from "@src/appV2/Reviews/api/useWorkplaceComments";
import { Reaction } from "@src/appV2/Reviews/types";
import { type QueryClient } from "@tanstack/react-query";
import cloneDeep from "lodash/cloneDeep";

interface UpdateCommentListCacheParams {
  queryClient: QueryClient;
  commentId: string;
  workplaceId: string;
  commentsParams: WorkplaceCommentsParams;
  updates: {
    reaction?: WorkplaceCommentAttributesType["workerReaction"];
    anonymized?: boolean;
  };
}

interface UpdateCommentRepliesListCacheParams extends UpdateCommentListCacheParams {
  parentCommentId: string;
}

interface UpdateCommentListCacheWithRepliesCountParams {
  queryClient: QueryClient;
  parentCommentId: string;
  workplaceId: string;
}

/**
 * Helper function to update a comment's attributes based on the provided updates
 */
function updateCommentAttributes(
  attributes: WorkplaceCommentAttributesType,
  updates: {
    reaction?: WorkplaceCommentAttributesType["workerReaction"];
    anonymized?: boolean;
  }
): WorkplaceCommentAttributesType {
  const { reaction, anonymized } = updates;
  const { totalLikes } = attributes.aggregates;
  const updatedAttributes = { ...attributes };

  if (reaction) {
    updatedAttributes.workerReaction = reaction;
    updatedAttributes.aggregates.totalLikes = totalLikes + (reaction === Reaction.LIKE ? 1 : -1);
  }

  if (anonymized) {
    updatedAttributes.workerInfo.name = "Anonymous";
    delete updatedAttributes.workerInfo.profilePictureStatus;
    delete updatedAttributes.workerInfo.id;
  }

  return updatedAttributes;
}

/**
 * Helper function to update a comment in a list of comments
 */
function updateCommentInList<T extends WorkplaceCommentType[] | GetCommentResponse>(
  data: T | undefined,
  commentId: string,
  updateFunction: (comment: WorkplaceCommentType) => WorkplaceCommentType
): T | undefined {
  if (!isDefined(data)) {
    return data;
  }

  const newData = cloneDeep(data);

  if (Array.isArray(newData)) {
    const commentIndex = newData.findIndex((comment) => comment.id === commentId);
    if (commentIndex === -1) {
      return data;
    }

    newData[commentIndex] = updateFunction(newData[commentIndex]);
  } else {
    newData.data = updateFunction(newData.data);
  }

  return newData;
}

/**
 * This updates the query data for the workplace comments.
 * It updates the workerReaction and the totalLikes count.
 * It might be a subject to change if we decide to use pagination for the comments.
 */
export function updateCommentListCache({
  queryClient,
  commentId,
  updates,
  commentsParams,
}: UpdateCommentListCacheParams) {
  queryClient.setQueryData<WorkplaceCommentType[]>(
    getWorkplaceCommentsQueryKey(commentsParams),
    (data) =>
      updateCommentInList(data, commentId, (comment) => ({
        ...comment,
        attributes: updateCommentAttributes(comment.attributes, updates),
      }))
  );
}

/**
 * This updates the query data for the comment replies.
 * It updates the workerReaction and the totalLikes count.
 */
export function updateCommentRepliesListCache({
  queryClient,
  commentId,
  workplaceId,
  updates,
  parentCommentId,
}: UpdateCommentRepliesListCacheParams) {
  queryClient.setQueryData<WorkplaceCommentType[]>(
    getWorkplaceCommentsQueryKey({ workplaceId, filter: { parentCommentId } }),
    (data) =>
      updateCommentInList(data, commentId, (comment) => ({
        ...comment,
        attributes: updateCommentAttributes(comment.attributes, updates),
      }))
  );
}

/**
 * This updates the query data for the single comment.
 * It updates the workerReaction and the totalLikes count.
 */
export function updateSingleCommentCache({
  queryClient,
  commentId,
  workplaceId,
  updates,
}: UpdateCommentListCacheParams) {
  const queryKey = [getCommentUrl({ workplaceId, commentId }), null];

  queryClient.setQueryData<GetCommentResponse>(queryKey, (oldResponse) =>
    updateCommentInList(oldResponse, commentId, (comment) => ({
      ...comment,
      attributes: updateCommentAttributes(comment.attributes, updates),
    }))
  );
}

export function updateCommentListCacheWithRepliesCount({
  queryClient,
  parentCommentId,
  workplaceId,
}: UpdateCommentListCacheWithRepliesCountParams) {
  queryClient.setQueryData<WorkplaceCommentType[]>(
    getWorkplaceCommentsQueryKey({ workplaceId }),
    (data) =>
      updateCommentInList(data, parentCommentId, (comment) => ({
        ...comment,
        attributes: {
          ...comment.attributes,
          aggregates: {
            ...comment.attributes.aggregates,
            totalReplies: comment.attributes.aggregates.totalReplies + 1,
          },
        },
      }))
  );
}
