import { BottomSheet } from "@clipboard-health/ui-components";
import { LoadingButton, Text, Title, type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent, Stack } from "@mui/material";
import { useToast } from "@src/appV2/lib/Notifications/Toasts/useToast";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import { usePatchComment } from "@src/appV2/Reviews/api/usePatchComment";

interface OnAnonymizedProps {
  commentId: string;
  workplaceId: string;
}

interface ConfirmAnonymizeCommentBottomSheetProps {
  modalState: UseModalState;
  commentId: string;
  workplaceId: string;
  onAnonymized: (props: OnAnonymizedProps) => void;
}

export function ConfirmAnonymizeCommentBottomSheet(props: ConfirmAnonymizeCommentBottomSheetProps) {
  const { modalState, commentId, workplaceId, onAnonymized } = props;
  const { showSuccessToast, showErrorToast } = useToast();

  const { mutate: patchComment, isLoading: commentIsUpdating } = usePatchComment(
    { workplaceId, commentId },
    {
      onSuccess: () => {
        onAnonymized({ commentId, workplaceId });
        showSuccessToast("Your review is now anonymous");
        modalState.closeModal();
      },
      onError: () => {
        showErrorToast("Something went wrong while updating the review");
      },
    }
  );

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter
          orientation="vertical"
          onClose={() => {
            modalState.closeModal();
          }}
        >
          <LoadingButton
            fullWidth
            variant="contained"
            size="large"
            isLoading={commentIsUpdating}
            onClick={async () => {
              patchComment({ anonymous: true });
            }}
          >
            Confirm
          </LoadingButton>
        </DialogFooter>
      }
    >
      <DialogContent sx={{ backgroundColor: "unset", py: 8, mt: 8 }}>
        <Stack direction="column" alignItems="center" spacing={9}>
          <Title variant="h2" component="h2" sx={{ textAlign: "center", textWrap: "balance" }}>
            Change your review to anonymous?
          </Title>

          <Text>
            This will remove your name and any other personal information from the review.
          </Text>
        </Stack>
      </DialogContent>
    </BottomSheet>
  );
}
