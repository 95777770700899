import { type WorkplaceCommentsParams } from "@src/appV2/Reviews/api/useWorkplaceComments";
import { type Reaction } from "@src/appV2/Reviews/types";
import { useQueryClient } from "@tanstack/react-query";

import { updateCommentListCache, updateSingleCommentCache } from "./queryCache";

interface CommentUpdates {
  reaction?: Reaction;
  anonymized?: boolean;
}

interface UseUpdateCachedCommentProps {
  workplaceId: string;
  commentsParams: WorkplaceCommentsParams;
}

export function useUpdateCachedComment(props: UseUpdateCachedCommentProps) {
  const { workplaceId, commentsParams } = props;

  const queryClient = useQueryClient();

  const updateComment = (commentId: string, updates: CommentUpdates) => {
    updateCommentListCache({
      queryClient,
      commentId,
      workplaceId: String(workplaceId),
      commentsParams,
      updates,
    });

    updateSingleCommentCache({
      queryClient,
      commentId,
      workplaceId: String(workplaceId),
      commentsParams,
      updates,
    });
  };

  return { updateComment };
}
