import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { Paper, Stack } from "@mui/material";
import { FullScreenDialog } from "@src/appV2/lib/Dialogs";
import { PageHeaderTopRow } from "@src/appV2/redesign/components/PageHeaderTopRow";
import { type WorkplaceCommentModalRoutePathParams } from "@src/appV2/redesign/ShiftDiscovery/types";
import { useGetComment } from "@src/appV2/Reviews/api/useGetComment";
import { useWorkplaceComments } from "@src/appV2/Reviews/api/useWorkplaceComments";
import { Reaction } from "@src/appV2/Reviews/types";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { parseISO } from "date-fns";
import { useParams } from "react-router-dom";

import { WorkplaceReviewCommentCard } from "./Card";
import { WorkplaceReviewCommentAuthorInfo } from "./CommentAuthorInfo";
import { WorkplaceReviewCommentLikesButton } from "./LikesButton";
import { WorkplaceCommentPostReplyFooter } from "./PostReplyFooter";
import { WorkplaceReviewCommentRepliesAndLikesWrapper } from "./RepliesAndLikesWrapper";
import { WorkplaceReviewCommentRepliesCountIcon } from "./RepliesCountIcon";
import { useUpdateCachedComment } from "./useUpdateCachedComment";

interface WorkplaceCommentModalProps {
  modalState: UseModalState;
  workplaceId: string;
}

export function WorkplaceCommentModal(props: WorkplaceCommentModalProps) {
  const { modalState, workplaceId } = props;

  const { commentId } = useParams<WorkplaceCommentModalRoutePathParams>();
  const worker = useDefinedWorker();

  // TODO: This modal needs to be split into a container component that handles loading states
  // and a presentational component that receives the destructured comment data
  const { data: commentData, isSuccess: isCommentSuccess } = useGetComment({
    workplaceId,
    commentId,
  });

  const commentsParams = {
    workplaceId,
    filter: {
      parentCommentId: commentId,
    },
  };

  const { data: replies, isSuccess: isRepliesSuccess } = useWorkplaceComments(commentsParams);

  const sortedReplies =
    replies?.sort((a, b) => {
      return (
        new Date(b.attributes.createdAt).getTime() - new Date(a.attributes.createdAt).getTime()
      );
    }) ?? [];

  const { updateComment } = useUpdateCachedComment({
    workplaceId,
    commentsParams,
  });

  return (
    <FullScreenDialog modalState={modalState} variant="tertiary">
      <PageHeaderTopRow />

      <Stack sx={{ overflow: "hidden", height: "100%" }}>
        <Paper variant="tertiary" sx={{ px: 7, paddingBottom: 8 }}>
          <Text semibold variant="h6" sx={{ paddingBottom: 8 }}>
            {commentData?.data.attributes.text}
          </Text>

          {isCommentSuccess && (
            <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
              <WorkplaceReviewCommentAuthorInfo
                workerName={commentData.data.attributes.workerInfo.name}
                workerId={commentData.data.attributes.workerInfo.id}
                createdAt={parseISO(commentData.data.attributes.createdAt)}
              />

              <WorkplaceReviewCommentRepliesAndLikesWrapper>
                <WorkplaceReviewCommentRepliesCountIcon
                  count={commentData.data.attributes.aggregates.totalReplies}
                />
                <WorkplaceReviewCommentLikesButton
                  workplaceId={workplaceId}
                  commentId={commentId}
                  isLiked={commentData.data.attributes.workerReaction === Reaction.LIKE}
                  count={commentData.data.attributes.aggregates.totalLikes}
                  onLikeChanged={(reaction) => {
                    updateComment(commentId, { reaction: reaction ?? undefined });
                  }}
                />
              </WorkplaceReviewCommentRepliesAndLikesWrapper>
            </Stack>
          )}
        </Paper>

        <Paper variant="secondary" sx={{ px: 5, py: 7, flexGrow: 1, overflow: "auto" }}>
          {isRepliesSuccess && (
            <Stack spacing={5}>
              {sortedReplies.length === 0 && (
                <Text variant="h6" sx={{ textAlign: "center" }}>
                  No replies yet. Be the first to reply!
                </Text>
              )}

              {sortedReplies.map((reply) => (
                <WorkplaceReviewCommentCard
                  key={reply.id}
                  workplaceId={workplaceId}
                  comment={reply}
                  viewerIsAuthor={reply.attributes.workerInfo.id === worker.userId}
                  onLikeChanged={(reaction) => {
                    updateComment(reply.id, { reaction: reaction ?? undefined });
                  }}
                  onAnonymized={() => {
                    updateComment(reply.id, { anonymized: true });
                  }}
                />
              ))}
            </Stack>
          )}
        </Paper>
      </Stack>

      <WorkplaceCommentPostReplyFooter workplaceId={workplaceId} parentId={String(commentId)} />
    </FullScreenDialog>
  );
}
